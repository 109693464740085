import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router-dom';
import WhoWeAre from '../aboutUs/WhoWeAre'
import HomePage from '../Home/Index'
import CustomSoftwareDevelopment from '../Service/CustomSoftwareDevelopment/CustomSoftwareDevelopment'
import OurCommitment from '../aboutUs/OurCommitment'
import CareerAtSharepointEmpower from '../aboutUs/CareerAtSharepointEmpower'
import ContactUs from '../aboutUs/ContactUs'
import FindUs from '../aboutUs/FindUs'
import JobOpenings from '../aboutUs/JobOpenings'
import AssetManagementSystem from '../Product/AssetManagementSystem'
import LearningManagementPortal from '../Product/LearningManagementPortal'
import AuditInspection from '../Product/AuditInspection'
import ConstructionManagementSystem from '../Product/ConstructionManagementSystem'
import PrivacyPolicy from '../Footer/PrivacyPolicy'
import LegalTermOfUse from '../Footer/LegalTermOfUse'
import SiteMap from '../Footer/SiteMap'
import AMSGuide from '../Product/ProductGuidePages/AMSGuide'
import AuditGuide from '../Product/ProductGuidePages/AuditGuide'
import CMSGuide from '../Product/ProductGuidePages/CMSGuide'
import LMPGuide from '../Product/ProductGuidePages/LMPGuide'
import ScrollToTop from '../CommonComponents/ScrollToTop/ScrollToTop'
import LeaveManagementSystem from '../Product/LeaveManagementSystem'
import Helpdesk from '../Product/Helpdesk'
import SPEIntranet from '../Product/SPEIntranet'
import LeaveMSGuide from '../Product/ProductGuidePages/LeaveMSGuide'
import HelpdeskGuide from '../Product/ProductGuidePages/HelpdeskGuide'
import SPEIntranetGuide from '../Product/ProductGuidePages/SPEIntranetGuide'
import ApiDataProvider from '../CommonComponents/Userguide/ApiDataContext';
import ProductPricingList from '../Product/ProductPricingList';
import IMSGuide from '../Product/ProductGuidePages/IMSGuide';
import InventoryManagementSystem from '../Product/InventoryManagementSystem';
import Allproducts from '../Product/AllProducts/Allproducts';
import ApprovalSystem from '../Product/ApprovalSystem';
import ASGuide from '../Product/ProductGuidePages/ASGuide';
import Globalmap from '../Global Map/Globalmap';
import FAQ from '../Footer/FAQ';
import NotFound from './NotFound';
import ContactUsDailog from '../CommonComponents/ContactUs/ContactUsDailog';

const MasterPage = () => {
    return (
        <div>
            <ApiDataProvider>
                <ContactUsDailog />
                <Routes>
                    <Route exact path='/' element={<HomePage />} />
                    <Route path="*" element={<NotFound />} />

                    <Route exact path='/custom-software-development' element={<CustomSoftwareDevelopment />} />
                    <Route
                        path="/ServiceInnerPage"
                        element={<Navigate to="/custom-software-development" />}
                    />
                    <Route
                        path="/Maintance&Support"
                        element={<Navigate to="/maintenance-and-support" />}
                    />
                    <Route exact path='/about-us' element={<WhoWeAre />} />
                    <Route
                        path="/AboutCompany"
                        element={<Navigate to="/about-us" />}
                    />

                    <Route exact path='/our-commitment' element={<OurCommitment />} />
                    <Route
                        path="/OurCommitment"
                        element={<Navigate to="/our-commitment" />}
                    />

                    <Route exact path='/career-at-sharepointempower' element={<CareerAtSharepointEmpower />} />
                    <Route
                        path="/CareerAtSharepoint"
                        element={<Navigate to="/career-at-sharepointempower" />}
                    />

                    <Route exact path='/contact-us' element={<ContactUs />} />
                    <Route
                        path="/ContactUs"
                        element={<Navigate to="/contact-us" />}
                    />
                    <Route
                        path="/quotation"
                        element={<Navigate to="/quotation" />}
                    />

                    <Route exact path='/find-us' element={<FindUs />} />
                    <Route
                        path="/FindUs"
                        element={<Navigate to="/find-us" />}
                    />

                    <Route exact path='/all-products' element={<Allproducts />} />
                    <Route
                        path="/Allproducts"
                        element={<Navigate to="/all-products" />}
                    />

                    <Route exact path='/job-openings' element={<JobOpenings />} />
                    <Route
                        path="/JobOpening"
                        element={<Navigate to="/job-openings" />}
                    />
                    <Route exact path='/global-map' element={<Globalmap />} />
                    <Route
                        path="/globalmap"
                        element={<Navigate to="/global-map" />}
                    />

                    <Route exact path='/asset-management-system' element={<AssetManagementSystem />} />
                    <Route
                        path="/AssetManagement"
                        element={<Navigate to="/asset-management-system" />}
                    />
                    <Route exact path='/guidelines/asset-management-system' element={<AMSGuide />} />


                    <Route exact path='/learning-management-system' element={<LearningManagementPortal />} />
                    <Route
                        path="/LearningManagement"
                        element={<Navigate to="/learning-management-system" />}
                    />
                    <Route exact path='/guidelines/learning-management-system' element={<LMPGuide />} />

                    <Route exact path='/audit-inspection' element={<AuditInspection />} />
                    <Route
                        path="/AuditInspection"
                        element={<Navigate to="/audit-inspection" />}
                    />
                    <Route exact path='/guidelines/audit-inspection' element={<AuditGuide />} />

                    <Route exact path='/construction-management-system' element={<ConstructionManagementSystem />} />
                    <Route
                        path="/ConstructionManagement"
                        element={<Navigate to="/construction-management-system" />}
                    />
                    <Route exact path='/guidelines/construction-management-system' element={<CMSGuide />} />
                    <Route exact path='/leave-management-system' element={<LeaveManagementSystem />} />
                    <Route exact path='/guidelines/leave-management-system' element={<LeaveMSGuide />} />

                    <Route exact path='/helpdesk-system' element={<Helpdesk />} />
                    <Route exact path='/guidelines/helpdesk-system' element={<HelpdeskGuide />} />

                    <Route exact path='/spe-intranet' element={<SPEIntranet />} />
                    <Route exact path='/guidelines/spe-intranet' element={<SPEIntranetGuide />} />

                    <Route exact path='/inventory-management-system' element={<InventoryManagementSystem />} />
                    <Route exact path='/guidelines/inventory-management-system' element={<IMSGuide />} />

                    <Route exact path='/approval-system' element={<ApprovalSystem />} />
                    <Route exact path='/guidelines/approval-system' element={<ASGuide />} />

                    <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route exact path='/faqs' element={<FAQ />} />

                    <Route exact path='/legal-terms-of-use' element={<LegalTermOfUse />} />
                    <Route
                        path="/terms-and-condition"
                        element={<Navigate to="/legal-terms-of-use" />}
                    />
                    <Route exact path='/site-map' element={<SiteMap />} />
                    <Route exact path='/product-pricing-list' element={<ProductPricingList />} />

                    <Route path="/guidelines/learning-management-portal/:level1category" element={<LMPGuide />} />
                    <Route path="/guidelines/construction-management-system/:level1category" element={<CMSGuide />} />
                    <Route path="/guidelines/helpdesk/:level1category" element={<HelpdeskGuide />} />
                    <Route path="/guidelines/asset-management-system/:level1category" element={<AMSGuide />} />
                    <Route path="/guidelines/spe-intranet/:level1category" element={<SPEIntranetGuide />} />
                    <Route path="/guidelines/leave-management-system/:level1category" element={<LeaveMSGuide />} />
                    <Route path="/guidelines/audit-inspection/:level1category" element={<AuditGuide />} />
                    <Route path="/guidelines/inventory-management-system/:level1category" element={<IMSGuide />} />
                    <Route path="/guidelines/approval-system/:level1category" element={<ASGuide />} />


                    <Route path="/guidelines/learning-management-portal/:level1category/:level2categoryitem" element={<LMPGuide />} />
                    <Route path="/guidelines/construction-management-system/:level1category/:level2categoryitem" element={<CMSGuide />} />
                    <Route path="/guidelines/helpdesk/:level1category/:level2categoryitem" element={<HelpdeskGuide />} />
                    <Route path="/guidelines/asset-management-system/:level1category/:level2categoryitem" element={<AMSGuide />} />
                    <Route path="/guidelines/spe-intranet/:level1category/:level2categoryitem" element={<SPEIntranetGuide />} />
                    <Route path="/guidelines/leave-management-system/:level1category/:level2categoryitem" element={<LeaveMSGuide />} />
                    <Route path="/guidelines/audit-inspection/:level1category/:level2categoryitem" element={<AuditGuide />} />
                    <Route path="/guidelines/inventory-management-system/:level1category/:level2categoryitem" element={<IMSGuide />} />
                    <Route path="/guidelines/approval-system/:level1category/:level2categoryitem" element={<ASGuide />} />


                    <Route path="/guidelines/learning-management-portal/:level1category/:level2categoryitem/:level3categoryitem" element={<LMPGuide />} />
                    <Route path="/guidelines/construction-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<CMSGuide />} />
                    <Route path="/guidelines/helpdesk/:level1category/:level2categoryitem/:level3categoryitem" element={<HelpdeskGuide />} />
                    <Route path="/guidelines/asset-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<AMSGuide />} />
                    <Route path="/guidelines/spe-intranet/:level1category/:level2categoryitem/:level3categoryitem" element={<SPEIntranetGuide />} />
                    <Route path="/guidelines/leave-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<LeaveMSGuide />} />
                    <Route path="/guidelines/audit-inspection/:level1category/:level2categoryitem/:level3categoryitem" element={<AuditGuide />} />
                    <Route path="/guidelines/inventory-management-system/:level1category/:level2categoryitem/:level3categoryitem" element={<IMSGuide />} />
                    <Route path="/guidelines/approval-system/:level1category/:level2categoryitem/:level3categoryitem" element={<ASGuide />} />
                </Routes>
            </ApiDataProvider>
            {/* <ChatBot /> */}
            <ScrollToTop />
        </div>
    )
}

export default MasterPage


